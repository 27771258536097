import React, { useState, useEffect } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import WOW from 'wowjs'
import { useNavigate } from 'react-router-dom';

import {
    DDLogo, ddLogo
} from "../components/img/svg.js";


const BurgerMenu = ({ setBurgerMenu, burgerMenu, scrollToAnchor }) => {
    const navigate = useNavigate();
    const [isScrollDisabled, setIsScrollDisabled] = useState(false);




    const navigateHandler = (path) => {

        navigate(path)
        setBurgerMenu(false)
    }


    useEffect(() => {

        const wow = new WOW.WOW();
        wow.init();

        // При отображении компонента BurgerMenu отключаем прокрутку
        if (burgerMenu) {
            document.body.style.overflow = 'hidden';
            setIsScrollDisabled(true);
        } else {
            document.body.style.overflow = '';
            setIsScrollDisabled(false);
        }

        // Возвращаем функцию очистки при размонтировании компонента
        return () => {
            document.body.style.overflow = '';
        };
    }, [burgerMenu]);


    const scrollToHandler = () => {
        setBurgerMenu(false)
        scrollToAnchor()
    }


    return (
        <>


            <div className=' lg:hidden flex flex-row justify-between '>


                <div className='text-[#fff] items-center gap-1 flex flex-row transition-colors duration-700 rounded py-2 px-4 hover:cursor-pointer gogh-heavy text-[30px]' onClick={() => { navigate('/') }}>
                    <div className='w-[30px]'>
                        < DDLogo />
                    </div>
                    <div className=''>DEEPDEV</div>
                </div>

                {burgerMenu ?
                    <div className='flex flex-col'>
                        <div className='flex flex-row justify-end'>
                            <XMarkIcon className='w-[35px] m-3 text-[#fff]' onClick={() => { setBurgerMenu(false) }} />
                        </div>
                    </div>
                    :
                    <>
                        <div className='flex flex-row justify-end'>
                            <Bars3Icon className='w-[35px] m-3 text-[#fff]' onClick={() => { setBurgerMenu(true) }} />
                        </div>
                    </>
                }
            </div>

            {
                burgerMenu
                    ?
                    <div className='flex flex-col h-screen items-center gap-2 text-[25px] mt-[0px] text-[#fff]'>
                        <div className="wow fadeIn hover:cursor-pointer" data-wow-delay="0.1s" onClick={() => { navigateHandler('/showcase') }}><div>Проекты</div></div>
                        <div className="wow fadeIn hover:cursor-pointer" data-wow-delay="0.2s" onClick={() => { navigateHandler('/technology') }}><div>Технологии</div></div>
                        {/* <div className="wow fadeIn" data-wow-delay="0.3s"><div>Услуги</div></div>
                        <div className="wow fadeIn" data-wow-delay="0.4s"><div>Мобильная разработка</div></div> */}
                        <div className="wow fadeIn hover:cursor-pointer" data-wow-delay="0.5s" onClick={() => { navigateHandler('/about') }}><div>О нас</div></div>
                        {/* <div onClick={() => { scrollToHandler() }} >Заказать разработку</div> */}

                        <div onClick={() => { scrollToHandler() }} className='hover:bg-[#000] bg-[#5E3FDB] text-[#fff] duration-700 mt-[20px]  py-2 px-4 hover:cursor-pointer' >
                            Заказать разработку
                        </div>

                    </div>
                    :
                    <></>
            }


        </>
    );
};

export default BurgerMenu;








