import React, { useState, useEffect, useRef } from 'react'
import WOW from 'wowjs'
import { ArrowDownIcon } from '@heroicons/react/24/outline'
import StickyHeader from '../components/StickyHeader.js'
import Foooter from '../components/Footer.js'
import BurgerMenu from '../components/BurgerMenu.js'
import TranscaledSquares from '../components/TranscaledSquares.js'
import TranscaledSquares2 from '../components/TranscaledSquares2.js'
import ArrowAnchor from '../components/ArrowAnchor.js'
import PortfolioProject from '../components/PortfolioProject.js'
import Technologies from '../components/Technologies';
import {
  DDLogo, ddLogo
} from "../components/img/svg.js";

export const Main = () => {

  const footer = useRef(null);
  const header = useRef(null);
  const showCase = useRef(null);
  const transcaledSquares = useRef(null);
  const [burgerMenu, setBurgerMenu] = useState(false)

  const scrollToAnchor = () => {
    footer.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToTop = () => {
    header.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToShowCase = () => {
    showCase.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToTranscaledSquares = () => {
    transcaledSquares.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);


  return (
    <>
      <div className='scrollbar-hide'>

        <div ref={header}></div>
        <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
        <StickyHeader scrollToAnchor={scrollToAnchor} />
        {/* w-[calc(100vw-170px)] */}
        <div className=' flex flex-col justify-center pl-[10px] md:ml-[100px]  text-[30px]  md:text-[42px] lg:text-[62px] xl:text-[72px] 2xl:text-[92px]  md:h-[calc(100vh-61px)] h-[300px]'>
          <div className='w-full'>
            <div data-wow-delay="0.5s" className=' gogh-heavy text-[#ffffff]  max-w-[600px] wow fadeIn'>
              ПРОЕКТИРОВАНИЕ
            </div>
            <div data-wow-delay="0.7s" className='gogh-heavy text-[#ffffff]  max-w-[600px] wow fadeIn'>
              РАЗРАБОТКА
            </div>
            <div data-wow-delay="0.9s" className='gogh-heavy text-[#ffffff]  max-w-[600px] wow fadeIn'>
              MVP
            </div>
          </div>

          <div className='w-full flex justify-end'>
            {/* <div className=' bg-[#5E3FDB] md:mr-[200px] mr-[10px] gogh-heavy text-[#000]  text-[50px] md:text-[62px]  
           lg:text-[82px] xl:text-[112px]    wow fadeIn'>DEEPDEV</div> */}

            <div className=' absolute md:mr-[100px] mr-[10px] md:mt-[-140px] wow fadeIn w-1/4 '>
              < DDLogo className="w-full" />
            </div>

          </div>


        </div>

        <div className='md:flex md:items-center md:justify-center md:w-full h-[62px] border-t-[1px] border-[#B480FD] hidden md:block '>
          <ArrowDownIcon className='text-[#B480FD] w-[40px] hover:cursor-pointer' onClick={() => { scrollToTranscaledSquares() }} />
        </div>
        {/* <div className='h-[100px]'></div> */}
        {/* <div className='w-full bg-[#ccc]'>1</div> */}

        <TranscaledSquares transcaledSquares={transcaledSquares} scrollToShowCase={scrollToShowCase} />
        {/* <TranscaledSquares2 transcaledSquares={transcaledSquares} scrollToShowCase={scrollToShowCase} /> */}

        <div className='flex flex-col gap-[100px]'>

          <Technologies />

          <PortfolioProject showCase={showCase} />

          {/* <div className="wow fadeIn"> <div className='w-full h-[100px] bg-[#000]'></div> </div> */}
          <Foooter footer={footer} />

          <ArrowAnchor scrollToTop={scrollToTop} />
        </div>
      </div >

    </>
  )
}

