import React, { useState, useEffect, useRef } from 'react'
import StickyHeader from '../../components/StickyHeader.js'
import WOW from 'wowjs'
import { ClockIcon, Code, WebPage, GitBranch } from "../../components/img/svg.js";
// import webPAge from "../../components/img/web-page-svgrepo-com.svg";
// import git from "../../components/img/git-branch-svgrepo-com.svg";
import SdmtImg from "../../components/img/sdmt.png";
import BurgerMenu from '../../components/BurgerMenu'
import ontoryShowCase1 from "../../components/img/ontoryShowCase/2.png";
import ontoryShowCase2 from "../../components/img/ontoryShowCase/4.png";
import ontoryShowCase3 from "../../components/img/ontoryShowCase/6.png";
import ontoryShowCase4 from "../../components/img/ontoryShowCase/7.png";
import ontoryShowCase5 from "../../components/img/ontoryShowCase/5.png";


import Foooter from '../../components/Footer.js'
export const SDMT = () => {


  const footer = useRef(null);
  const [burgerMenu, setBurgerMenu] = useState(false)

  const scrollToAnchor = () => {
    footer.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);


  return (
    <>

      <div className='scrollbar-hide'>
      <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
        <StickyHeader scrollToAnchor={scrollToAnchor} />
        <div className='flex flex-col md:flex-row w-full  md:mt-[70px]'>

          <div className='w-full md:w-1/2 text-[#fff]  p-[20px] flex flex-col justify-center items-center'>

            <div data-wow-delay="0.2s" className="text-[32px] 2xl:text-[62px] xl:text-[60px] lg:text-[48px] md:text-[36px] gogh-heavy wow fadeIn ">SOFTWARE DEVELOPMENT MANAGEMENT TOOL</div>

            <div data-wow-delay="0.4s" className='text-[18px] 2xl:text-[25px] xl:text-[24px] lg:text-[20px] md:text-[16px] wow fadeIn'>
              Software Development Management Tool – инновационный профессиональный инструмент для управления ИТ-проектами и повышения эффективности ИТ-функции
            </div>

          </div>




          <img
            data-wow-delay="0.6s"
            className="w-full md:w-1/2 md:h-[calc(30vw)] h-[calc(40vh)] mr-[10px] wow fadeIn" autoPlay muted loop style={{ position: 'relative' }}
            src={SdmtImg}
            alt='Изображение'
          />



        </div>

        <div className='text-[#fff] w-full flex flex-col items-center'>




        <div className='flex flex-col sm:flex-row items-center w-full  mt-[30px]'>
            {/* <div className='flex flex-row gap-4'> */}
            <div className='flex flex-col xl:flex-row justify-around w-full items-center gap-4'>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="0.8s" >
                <div className='text-[#B480FD]'>100% российское ПО</div>
                <div>Решения сделаны в РФ и находятся в Реестре российского ПО</div>
              </div>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1s">
                <div className='text-[#B480FD]'> Передовые технологии</div>
                <div> Использованы алгоритмы машинного обучения и искусственного интеллекта</div>
              </div>
            </div>
            {/* </div> */}

            {/* <div className='flex flex-row gap-4'> */}
            <div className='flex flex-col xl:flex-row justify-around w-full items-center gap-4'>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1.2s">
                <div className='text-[#B480FD]'>Максимальная безопасность</div>
                <div> Соответствует 152-ФЗ и корпоративным требованиям информационной безопасности</div>
              </div>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1.4s">
                <div className='text-[#B480FD]'>Комплексный подход</div>
                <div>Функциональная готовность системы не уступает ведущим зарубежным решениям</div>
              </div>
            </div>
            {/* </div> */}
          </div>






          <div data-wow-delay="0.4s" className=' w-full flex flex-col items-center mt-[100px] wow fadeIn' >
            {/* <div> */}
            <div className='gogh text-[22px] text-[#B480FD]  '>
              ABOUT THE PRODUCT
            </div>
            <div className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff]  '>
              О ПРОДУКТЕ
            </div>
            {/* </div> */}

            <p className='gogh text-center leading-none text-[22px] text-[#ffffff]  max-w-[1200px]'>
              Software Development Management Tool – инновационный профессиональный инструмент для управления ИТ-проектами и повышения эффективности ИТ-функции
              автоматизирует: 
              разработку целевой архитектуры ПО; 
              планирование и выстраивание процессов при разработке ПО; 
              подбор оптимальных по стоимости ресурсов; 
              поддержку разработчиков начального и среднего уровней в процессе работы,
              выявление отклонений от стандартов при разработке ПО, а также формирование рекомендаций по улучшению производительности и развитию навыков и членов команды.
            </p>

          </div>

          <div className='h-[100px]'></div>





        </div>

        <div className='h-[100px]'></div>
      </div >

      <Foooter footer={footer} />
    </>
  )
}

