import React, { useState, useEffect, useRef } from 'react';
import WOW from 'wowjs'
import { useNavigate } from 'react-router-dom';

import {
  DDLogo, ddLogo
} from "../components/img/svg.js";

const StickyHeader = ({ scrollToAnchor }) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    let prevScrollTop = window.pageYOffset;

    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      setIsScrolled(scrollTop > prevScrollTop);
      setIsScrolledToBottom(scrollTop + windowHeight < documentHeight);

      prevScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`hidden bg-[#fff] lg:block sticky-header  ${isScrolled ? 'scrolled' : ''} ${isScrolledToBottom ? '' : 'scrolledToBottom'}`} >
      <div className='flex flex-row text-[20px] items-center justify-between mx-4'>
        {/* <div className="wow fadeInDown" data-wow-delay="0.5s"> */}
        <div className='flex flex-row gap-1 items-center ' >
          <div className='w-[30px] hover:cursor-pointer ' onClick={() => { navigate('/') }}>
            < DDLogo className="w-full" />
          </div>
          <div className='hover:text-[#1F2833] duration-700 rounded py-2 pr-2 gogh-heavy hover:cursor-pointer text-[30px]' onClick={() => { navigate('/') }}>
            <span className='hover:text-[#5E3FDB] duration-1000'>D</span>
            <span className='hover:text-[#5E3FDB] duration-1000'>E</span>
            <span className='hover:text-[#5E3FDB] duration-1000'>E</span>
            <span className='hover:text-[#5E3FDB] duration-1000'>P</span>
            <span className='hover:text-[#5E3FDB] duration-1000'>D</span>
            <span className='hover:text-[#5E3FDB] duration-1000'>E</span>
            <span className='hover:text-[#5E3FDB] duration-1000'>V</span>
          </div>
          <div className='hover:bg-[#B480FD]  hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer' onClick={() => { navigate('/showcase') }} >Портфолио</div>
          <div className='hover:bg-[#B480FD]  hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer' onClick={() => { navigate('/technology') }}>Технологии</div>
          {/* <div className='hover:bg-[#80808040]  hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer'>Услуги</div> */}
          {/* <div className='hover:bg-[#45A29E] hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer'>UI/UX</div> */}
          {/* <div className='hover:bg-[#80808040]  hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer'>Мобильная разработка</div> */}
          <div className='hover:bg-[#B480FD]  hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer' onClick={() => { navigate('/about') }}>О нас</div>
          {/* <div className='hover:bg-[#80808040]  hover:text-[#1F2833] duration-700 rounded py-2 px-4 hover:cursor-pointer'>En</div> */}
        </div>
        {/* </div> */}
        <div className='whitespace-nowrap hover:bg-[#000] bg-[#5C3BFE] text-[#fff] duration-700  py-2 px-4 hover:cursor-pointer' onClick={scrollToAnchor} >Заказать разработку</div>
      </div>
    </header>
  );
};

export default StickyHeader;
