import React, { useEffect } from 'react';
import ItTurbo from "./img/it-turbo.png";
import TeensKids from "./img/teens&kids.png";
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import WOW from 'wowjs'

const PortfolioProject = ({ showCase }) => {
  const navigate = useNavigate();


  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);



  return (
    <>

      <div className='flex flex-col items-center'>

        <div ref={showCase} className=' w-full flex flex-col items-center'>
          <div data-wow-delay="0.2s" className='gogh text-[22px] text-[#B480FD]  wow fadeIn'>
            SHOWCASE
          </div>
          <div data-wow-delay="0.4s" className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff] wow fadeIn'>
            ПОРТФОЛИО
          </div>
          <p data-wow-delay="0.6s" className='gogh text-center leading-none text-[18px] text-[#ffffff]  max-w-[700px] wow fadeIn'>
            Cписок проектов, в которых нам есть что показать:
          </p>
        </div>


        <div className='w-full flex md:flex-row px-1 flex-col justify-around mt-[20px] mt-[50px] gap-[30px]  md:gap-0'>

          <div data-wow-delay="0.8s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] wow fadeIn'>
            <div onClick={() => { navigate('/it-turbo'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <img
                className='object-cover bg-[#000] hover:bg-[#7051A0] hover:scale-110 duration-1000'
                src={ItTurbo}
                alt='Изображение'
              />
            </div>
            <div className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000' onClick={() => { navigate('/it-turbo'); }}>IT MANAGEMENT</div>
            <div className='text-[#fff] text-[18px] md:text-[16px] font-normal mt-[10px]'>
              Иновационное решение для IT-команд по повышению качества и скорости разработки IT-продуктов
            </div>
            <div onClick={() => { navigate('/it-turbo'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>



          <div data-wow-delay="1.0s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] wow fadeIn'>
            <div onClick={() => { navigate('/teenskids'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <img
                className='object-cover bg-[#000] hover:bg-[#7051A0] hover:scale-110 duration-1000'
                src={TeensKids}
                alt='Изображение'
              />
            </div>
            <div onClick={() => { navigate('/teenskids'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>TEENS & KIDS</div>
            <div className='text-[#fff] text-[18px] md:text-[16px] font-normal mt-[10px]'>Лидер в России по предоставлению подросткам и их родителям онлайн психологической помощи </div>
            <div onClick={() => { navigate('/teenskids'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>


          <div data-wow-delay="1.2s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] wow fadeIn'>
            <div onClick={() => { navigate('/afir'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px]  flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <video className="h-[250px] object-cover bg-[#000] hover:bg-[#fff] hover:scale-110 duration-1000" autoPlay muted loop style={{ position: 'relative' }}>
                <source src={`${process.env.PUBLIC_URL}/video/afirvideo2.mp4`} type="video/mp4" />
                Ваш браузер не поддерживает видео.
              </video>
            </div>
            <div onClick={() => { navigate('/afir'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>Collaborative Text Editor</div>
            <div className='text-[#fff] text-[18px] md:text-[16px] font-normal mt-[10px]'>Иинновационное решение для автоматизации цикла разработки ПО и ИТ-продуктов на заказ</div>
            <div onClick={() => { navigate('/afir'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>
        </div>


        <div onClick={() => { navigate('/showcase') }} className='hover:bg-[#B480FD] bg-[#5E3FDB] text-[#fff] duration-700 w-[300px] py-2 px-4 hover:cursor-pointer text-center hidden md:block mt-[50px]'  >
          Подробнее
        </div>
      </div>
    </>
  );
};

export default PortfolioProject;








