import React, { useState, useEffect, useRef } from 'react'
import ItTurboLarge from "../../components/img/it-turbo-large.png";
import StickyHeader from '../../components/StickyHeader.js'
import WOW from 'wowjs'
import BurgerMenu from '../../components/BurgerMenu'
// import  from "../../components/img/code.svg";
import { ClockIcon, Code, WebPage, GitBranch } from "../../components/img/svg.js";
// import clock from "../components/img/time-svgrepo-com.svg";
// import webPAge from "../../components/img/web-page-svgrepo-com.svg";
// import git from "../../components/img/git-branch-svgrepo-com.svg";
import Foooter from '../../components/Footer.js'
export const ItTurbo = () => {
  const footer = useRef(null);
  const [burgerMenu, setBurgerMenu] = useState(false)

  const scrollToAnchor = () => {
    footer.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);



  return (
    <>

      <div className='scrollbar-hide'>
        <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
        <StickyHeader scrollToAnchor={scrollToAnchor} />
        <div className='flex flex-col md:flex-row w-full  md:mt-[70px]'>

          <div className='w-full md:w-1/2 text-[#fff]  p-[20px] flex flex-col justify-center items-center'>

            <div data-wow-delay="0.2s" className="text-[32px] 2xl:text-[72px] xl:text-[60px] lg:text-[48px] md:text-[36px] gogh-heavy wow fadeIn ">IT MANAGEMENT</div>

            <div data-wow-delay="0.4s" className='text-[18px] 2xl:text-[28px] xl:text-[24px] lg:text-[20px] md:text-[16px] wow fadeIn'>
              Иновационное решение для IT-команд по повышению качества и скорости разработки IT-продуктов</div>

          </div>
          <img
            data-wow-delay="0.6s"
            className="w-full md:w-1/2 md:h-[calc(30vw)] h-[calc(40vh)] mr-[10px] wow fadeIn" autoPlay muted loop style={{ position: 'relative' }}
            src={ItTurboLarge}
            alt='Изображение'
          />



        </div>

        <div className='text-[#fff] w-full flex flex-col items-center'>




          <div className='flex flex-col sm:flex-row items-center w-full  mt-[30px]'>
            {/* <div className='flex flex-row gap-4'> */}
            <div className='flex flex-col xl:flex-row justify-around w-full items-center gap-4'>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="0.8s" >
                <div className='text-[#B480FD]'>100% российское ПО</div>
                <div>Решения сделаны в РФ и находятся в Реестре российского ПО</div>
              </div>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1s">
                <div className='text-[#B480FD]'> Передовые технологии</div>
                <div> Использованы алгоритмы машинного обучения и искусственного интеллекта</div>
              </div>
            </div>
            {/* </div> */}

            {/* <div className='flex flex-row gap-4'> */}
            <div className='flex flex-col xl:flex-row justify-around w-full items-center gap-4'>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1.2s">
                <div className='text-[#B480FD]'>Максимальная безопасность</div>
                <div> Соответствует 152-ФЗ и корпоративным требованиям информационной безопасности</div>
              </div>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1.4s">
                <div className='text-[#B480FD]'>Комплексный подход</div>
                <div>Функциональная готовность системы не уступает ведущим зарубежным решениям</div>
              </div>
            </div>
            {/* </div> */}
          </div>






          <div data-wow-delay="0.4s" className=' w-full flex flex-col items-center mt-[100px] wow fadeIn' >
            {/* <div> */}
            <div className='gogh text-[22px] text-[#B480FD]  '>
              ABOUT THE PRODUCT
            </div>
            <div className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff]  '>
              О ПРОДУКТЕ
            </div>
            {/* </div> */}

            <p className='gogh text-center leading-none text-[22px] text-[#ffffff]  max-w-[1200px]'>
              Инновационное решение для ИТ-команд по повышению качества и скорости
              разработки ИТ-продуктов с применением алгоритмов машинного обучения и искусственного интеллекта.

            </p>

          </div>

          <div className='h-[100px]'></div>



          <div className='flex flex-col gap-[50px] md:gap-[150px] w-full px-3 lg:w-2/3 px-3 justify-center items-center'>

            <div data-wow-delay="0.5s" className='flex flex-col-reverse md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>
              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                {/* <img
                  className="w-[400px]"
                  src={Code}
                  alt='Изображение'
                /> */}

                <Code width={"200px"} height={"200px"} />

              </div>
              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Интеллектуальный инструмент, упрощающий code-review</div>
                Автоматический поиск ошибок в коде
                (в изменениях, созданных посредством merge-request) с помощью ML и AI-алгоритмов. Анализ истории изменений в коде в основной
                ветке ИТ-проекта, подробная аналитика по каждому разработчику о количестве изменений, замечаний, исправлений и комментариев
              </div>
            </div>



            <div data-wow-delay="0.5s" className='flex flex-col md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>

              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Интеллектуальная система учета времени работы сотрудников</div>
                Классификация портрета разработчика с учетом анализа данных и результатов работы системы с целью выявления сильных
                и слабых разработчиков, фиксации динамики развития сотрудников и своевременного изменения карьерных возможностей
              </div>

              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                {/* <img
                  className="w-[300px]"
                  src={clock}
                  alt='Изображение'
                /> */}

                <ClockIcon width={"200px"} height={"200px"} />
              </div>
            </div>




            <div data-wow-delay="0.5s" className='flex flex-col-reverse md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>
              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                {/* <img
                  className="w-[300px]"
                  src={webPAge}
                  alt='Изображение'
                /> */}
                <WebPage width={"200px"} height={"200px"} />
              </div>
              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  ИИ для автоматической верстки web-страниц</div>
                Разработка новых продуктов и бизнес-процессов, никогда еще не была проще, разработчики
                получают готовую верстку прямо из Figma файлов, полученных от дизайнеров
              </div>
            </div>




            <div data-wow-delay="0.5s" className='flex flex-col md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>

              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Интеграция с системами контроля версий</div>
                Поддерживаем системы GitLab, GitHub, BitBucket и делаем базовую проверку кода
                на ошибки (Linters)
              </div>

              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                {/* <img
                  className="w-[300px]"
                  src={git}
                  alt='Изображение'
                /> */}
                <GitBranch width={"200px"} height={"200px"} />
              </div>
            </div>
          </div>


        </div>

        <div className='h-[100px]'></div>
      </div >

      <Foooter footer={footer} />

    </>
  )
}

