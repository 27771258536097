import { useState, useCallback } from 'react'

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
    // url = "http://127.0.0.1:5012" + url;
    setLoading(true);
    try {
      if (body) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }

      const response = await fetch(url, { method, body, headers });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Ошибка запроса на сервер');
      }

      setLoading(false);

      return data;
    } catch (e) {
      console.log(e)
      setLoading(false);
      setError(e.message);
      throw e;
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError };
};
