import React, { useState, useEffect, useRef } from 'react'
import { ArrowLongUpIcon } from '@heroicons/react/24/outline'

const ArrowAnchor = ({ scrollToTop }) => {

    const [arrowVisible, setArrowVisible] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            setArrowVisible(scrollPosition > 500);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <>
            {arrowVisible && <ArrowLongUpIcon onClick={() => { scrollToTop() }} className='w-[62px] fixed bottom-[50px] right-[50px] text-[#ccc] hover:cursor-pointer hover:text-[#fff]' />}
        </>
    );
};

export default ArrowAnchor;








