import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { PhoneIcon, InboxIcon } from '@heroicons/react/24/outline'
import { Telegram, WhatsApp } from "../components/img/svg.js";
import { useHttp } from '../hooks/http.hook'
import { PhoneOutlined, WarningOutlined } from '@ant-design/icons';


const Foooter = ({ footer }) => {

  function sendEmail() {
    window.location.href = 'mailto:deepdev.space@gmail.com';
  }


  const [lastRequestTime, setLastRequestTime] = useState(0)
  const { request } = useHttp()
  const { TextArea } = Input;

  const [form, setForm] = useState(
    {
      name: "",
      phone: "",
      email: "",
      commercial: ""
    }
  )

  const openNotification = (status, message, description) => {
    notification.open({
      message: message,
      description: description,
      icon: status == "success" ? <PhoneOutlined style={{ color: '#007F43' }} /> : <WarningOutlined style={{ color: '#FF8C00' }} />,
    });
  };




  const sendMessage = async () => {
    const currentTime = Date.now();
    try {
      if (form.phone.length >= 10) {
        // Проверяем, прошло ли уже 2 секунды с момента последнего запроса
        if (currentTime - lastRequestTime >= 2000) {
          openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");

          setLastRequestTime(currentTime) // Обновляем время последнего запроса
          const msg = await request('/api/all/sendMessage', 'POST', { ...form });
        } else {
          console.log("Слишком много запросов!")
          openNotification("warning", "Предупреждение!", "Слишком много запросов!");
        }
      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }



  return (
    <footer ref={footer} className='bg-[#5E3FDB] w-full text-[#fff] pb-[30px] md:pb-[30px]' >

      <div className='md:h-[calc(100vh-61px)] w-full flex flex-col md:flex-row justify-around gap-[50px] p-2 pt-[50px]'>

        {/*  w-full lg:flex lg:flex-row lg:justify-around */}
        <div className='flex flex-col gap-4 '>
          <div className='lg:text-[72px] text-[45px] gogh-heavy'>СВЯЗАТЬСЯ С НАМИ</div>
          <Input size="large" className='bg-[#5E3FDB] rounded-none' placeholder="Имя" value={form?.name}
            onChange={(e) => {
              setForm(prevState => ({
                ...prevState,
                name: e.target.value
              }))
            }} />
          <Input size="large" className='bg-[#5E3FDB]  rounded-none' placeholder="Email" value={form?.email}
            onChange={(e) => {
              setForm(prevState => ({
                ...prevState,
                email: e.target.value
              }))
            }} />
          <Input size="large" className='bg-[#5E3FDB] rounded-none' placeholder="Номер телефона" value={form?.phone}
            onChange={(e) => {
              setForm(prevState => ({
                ...prevState,
                phone: e.target.value
              }))
            }} />
          <textarea placeholder="Краткое описание проекта" value={form?.commercial}
            style={{ resize: "none" }} className='p-2 bg-[#5E3FDB] rounded-none border-[1px]' rows="6" cols="45" name="text"
            onChange={(e) => {
              setForm(prevState => ({
                ...prevState,
                commercial: e.target.value
              }))
            }}>
          </textarea>
          <div className='flex'><p className=' text-[#ccc] transition-colors duration-700 rounded py-2 px-4 hover:cursor-pointer hover:text-[#fff]'>Privacy Policy</p></div>
          <div className='hover:bg-[#fff] transition-color hover:text-[#000] transition-colors duration-700 rounded py-2 px-4 hover:cursor-pointer' onClick={() => { sendMessage() }}>Отправить</div>
        </div>


        <div className='flex flex-col gap-4'>
          <div className='lg:text-[72px] text-[45px] gogh-heavy '>КОНТАКТЫ</div>
          <div className='flex flex-row items-center gap-2 hover:cursor-pointer hover:text-[#ccc]' onClick={sendEmail}><InboxIcon className='w-[20px]' />
            deepdev.space@gmail.com</div>
          <div className='flex flex-row items-center gap-2'><PhoneIcon className='w-[20px]' />+79065851607</div>
          <a href='https://web-telegram.ru/#@deepdevspace' target='blank'><div className='flex flex-row items-center gap-2 hover:cursor-pointer hover:text-[#ccc]'> <Telegram width={"20px"} height={"20px"} />Telegram</div></a>
          <a href='https://wa.me/+79065851607' target='blank'><div className='flex flex-row items-center gap-2 hover:cursor-pointer hover:text-[#ccc]'><WhatsApp width={"20px"} height={"20px"} /> WhatsApp</div></a>
        </div>

      </div>

    </footer>
  );
};

export default Foooter;
