import React, { useState, useEffect, useRef } from 'react'
import WOW from 'wowjs'
import StickyHeader from '../components/StickyHeader.js'
import Foooter from '../components/Footer.js'
import BurgerMenu from '../components/BurgerMenu.js'
import ArrowAnchor from '../components/ArrowAnchor.js'
import PortfolioProject from '../components/PortfolioProject.js'

import {
  ElectronJs, NodeJS, ReactJs, SvelteJs,
  NextJs, Kiosk, Terminal, Desktop, Ios, Android, WebPage
} from "../components/img/svg.js";


export const TechnologiesAll = () => {

  const footer = useRef(null);
  const header = useRef(null);
  const showCase = useRef(null);
  const [burgerMenu, setBurgerMenu] = useState(false)

  const scrollToAnchor = () => {
    footer.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToTop = () => {
    header.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);



  return (
    <>
      <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
      <StickyHeader scrollToAnchor={scrollToAnchor} />
      <div className='scrollbar-hide flex flex-col md:gap-[100px] w-full gap-[60px]  '>
        <div ref={header}></div>


        <div ref={showCase} className=' w-full flex flex-col items-center justify-center gap-2'>
          <p data-wow-delay="0.1s" className='text-center  gogh text-[22px] text-[#B480FD] wow fadeIn'>
            DEVELOPMENT OF WEB, MOBILE AND DESKTOP APPS
          </p>

          <p data-wow-delay="0.2s" className=' text-center gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff] wow fadeIn'>
            РАЗРАБОТКА ВЕБ, МОБИЛЬНЫХ И ДЕСКТОПНЫХ ПРИЛОЖЕНИЙ
          </p>

          <p data-wow-delay="0.3s" className='gogh text-center leading-none text-[18px] text-[#ffffff]  max-w-[700px] wow fadeIn'>
            Мы предоставляем услуги Full-stack разработки продуктов с привязкой к конкретным KPI и бизнес-задачам.
          </p>
        </div>

        <div className='flex gogh flex-col text-[#fff] w-full items-center'>







          <div className='flex flex-col md:flex-row w-[70vw] justify-around items-center'>
            <div className='flex flex-row md:flex-col gap-[50px]'>
              <div data-wow-delay="0.4s" className='flex flex-col gap-4 items-center  wow fadeIn'>
                < WebPage width={"150px"} height={"150px"} />
                <div>Веб</div>
              </div>
              <div data-wow-delay="0.7s" className='flex flex-col gap-4 items-center  wow fadeIn'>
                < Desktop width={"150px"} height={"150px"} />
                <div>Десктоп</div>
              </div>
            </div>
            <div className='flex flex-row md:flex-col gap-[50px]'>
              <div data-wow-delay="0.5s" className='flex flex-col gap-4 items-center  wow fadeIn'>
                < Android width={"150px"} height={"150px"} />
                <div>Android</div>
              </div>
              <div data-wow-delay="0.8s" className='flex flex-col gap-4 items-center  wow fadeIn'>
                < Terminal width={"150px"} height={"150px"} />
                <div>POS терминал</div>
              </div>
            </div>
            <div className='flex flex-row md:flex-col gap-[50px]'>
              <div data-wow-delay="0.6s" className='flex flex-col gap-4 items-center wow fadeIn'>
                < Ios width={"150px"} height={"150px"} />
                <div>iOS</div>
              </div>
              <div data-wow-delay="0.9s" className='flex flex-col gap-4 items-center wow fadeIn'>
                < Kiosk width={"150px"} height={"150px"} />
                <div className=''>Интерактивный<br></br>терминал</div>
              </div>
            </div>
          </div>










        </div>

        <div ref={showCase} className=' w-full flex flex-col items-center gap-2'>
          <div className='gogh text-[22px] text-[#B480FD]  '>
            TECHNOLOGIES
          </div>
          <div className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff]  '>
            ТЕХНОЛОГИИ
          </div>
          <p className='gogh text-center leading-none text-[18px] text-[#ffffff]  max-w-[700px]'>
            Мы предоставляем услуги Full-stack разработки продуктов с привязкой к конкретным KPI и бизнес-задачам.
          </p>
        </div>

        <div className='w-full flex justify-center'>
          <div className='flex flex-row justify-between w-3/4'>
            < ReactJs width={"150px"} height={"150px"} />
            < SvelteJs width={"150px"} height={"150px"} />
            < NodeJS width={"150px"} height={"150px"} />
            < NextJs width={"150px"} height={"150px"} />
            < ElectronJs width={"150px"} height={"150px"} />
          </div>
        </div>
        <div ref={showCase} className=' w-full flex flex-col items-center gap-2'>
          <div className='gogh text-[22px] text-[#B480FD]  '>
            ADVANTAGES
          </div>
          <div className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff]  '>
            ПРИЕМУЩЕСТВА
          </div>
          <p className='gogh text-center leading-none text-[18px] text-[#ffffff]  max-w-[700px]'>
            Нашим преимуществом является узкий набор технологий. Такой подход позволяет “не распыляться” и держать экспертизу в Javascript на высоком уровне.
          </p>
        </div>


        <div className='flex gogh flex-row justify-around w-full mt-[50px] text-[#fff] '>
          <div className='flex flex-col  w-full justify-around md:flex-row'>
            <div className='flex flex-col  justify-around  items-center'>
              <div>MOBILE</div>
              < ReactJs width={"150px"} height={"150px"} />
              <div>React Native</div>
            </div>
            <div className='flex flex-col   items-center mt-[60px] md:mt-0'>
              <div>FRONTEND</div>
              < ReactJs width={"150px"} height={"150px"} />
              <div>React JS</div>
            </div>
          </div>
          <div className='flex flex-col  w-full justify-around md:flex-row '>
            <div className='flex flex-col   items-center'>
              <div>BACKEND</div>
              < NodeJS width={"150px"} height={"150px"} />
              <div>Node JS</div>
            </div>
            <div className='flex flex-col   items-center mt-[60px] md:mt-0'>
              <div>DESKTOP</div>
              < ElectronJs width={"150px"} height={"150px"} />
              <div>Electron JS</div>
            </div>
          </div>
        </div>

        <PortfolioProject showCase={showCase} />

        <Foooter footer={footer} />

        <ArrowAnchor scrollToTop={scrollToTop} />

      </div >

    </>
  )
}

