import React, { useState, useEffect, useRef } from 'react'
import WOW from 'wowjs'
import StickyHeader from '../components/StickyHeader.js'
import Foooter from '../components/Footer.js'
import BurgerMenu from '../components/BurgerMenu.js'
import ArrowAnchor from '../components/ArrowAnchor.js'
import PortfolioProject from '../components/PortfolioProject.js'
import { Carousel } from 'antd';
import {
    ElectronJs, NodeJS, ReactJs, SvelteJs,
    NextJs, Kiosk, Terminal, Desktop, Ios, Android, WebPage, Layer, ClockIcon, ClockIcon2, Individual, Code, Glasses, Team,
    Wave, Fire, Dollar, Dialog, GlassCube, Secure, Celendar, Test
} from "../components/img/svg.js";

const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

export const AboutUs = () => {



    const footer = useRef(null);
    const header = useRef(null);
    const showCase = useRef(null);
    const [burgerMenu, setBurgerMenu] = useState(false)


    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };


    const scrollToAnchor = () => {
        footer.current.scrollIntoView({ behavior: 'smooth' });
    }

    const scrollToTop = () => {
        header.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
        return () => {
            document.body.style.overflow = '';
        };
    }, []);



    return (
        <>
            <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
            <StickyHeader scrollToAnchor={scrollToAnchor} />
            <div className='scrollbar-hide flex flex-col md:gap-[50px] gap-[60px] items-center '>
                <div ref={header}></div>


                <div ref={showCase} className=' w-full flex flex-col items-center mt-[50px]'>
                    <div data-wow-delay="0.1s" className='gogh text-[22px] text-[#B480FD] wow fadeIn'>
                        About Us
                    </div>
                    <div data-wow-delay="0.2s" className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff] wow fadeIn'>
                        О нас
                    </div>
                    <p data-wow-delay="0.3s" className='gogh text-center leading-none text-[18px] text-[#ffffff]  max-w-[700px] wow fadeIn'>
                        И наших преимуществах
                    </p>

                    <div className=' flex-row lg:flex-col justify-between mx-4 gap-4 hidden md:flex mt-0 md:mt-[50px]'>
                        <div className='flex flex-col lg:flex-row max-w-[1300px] justify-between'>
                            <div data-wow-delay="0.4s" className='flex flex-col w-full lg:w-1/4 h-[250px] wow fadeIn'>
                                <div className='flex flex-row items-center gap-2 ' > <div className='w-[50px]'> < Layer width={"50px"} height={"50px"} /> </div><div className='text-[#B480FD] text-[22px]'>  Комплексный подход</div></div>
                                <div className='text-[#ffffff] text-[18px]'>
                                    Ведём проекты от прототипирования до поддержки после запуска. Готовы подключиться на любом этапе и взять под контроль все процессы.
                                </div>
                            </div>

                            <div data-wow-delay="0.5s" className='flex flex-col w-full lg:w-1/4 h-[250px] wow fadeIn'>
                                <div className='flex flex-row items-center gap-2' > <div className='w-[50px]'>  < ClockIcon width={"50px"} height={"50px"} /> </div> <div className='text-[#B480FD] text-[22px]'>Оперативность</div></div>
                                <div className='text-[#ffffff] text-[18px]'>
                                    Разделяем крупные проекты на этапы, соблюдаем договоренности о сроках, запускаем проекты без задержек.
                                </div>
                            </div>

                            <div data-wow-delay="0.6s" className='flex flex-col w-full lg:w-1/4 h-[250px] wow fadeIn'>
                                <div className='flex flex-row items-center gap-2' >  <div className='w-[50px]'> < Individual width={"50px"} height={"50px"} />  </div> <div className='text-[#B480FD] text-[22px]'>Индивидуальное решение </div></div>
                                <div className='text-[#ffffff] text-[18px]'>
                                    Разработаем решение, которое подойдёт именно для вашего бизнеса с учётом особенностей бизнес-модели и предпочтений ваших клиентов.
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col lg:flex-row max-w-[1300px] justify-between '>
                            <div data-wow-delay="0.7s" className='flex flex-col w-full lg:w-1/4 h-[250px] wow fadeIn'>
                                <div className='flex flex-row items-center gap-2 ' > <div className='w-[50px]'> < Code width={"50px"} height={"50px"} /> </div><div className='text-[#B480FD] text-[22px]'>Чистый код</div></div>
                                <div className='text-[#ffffff] text-[18px]'>
                                    Отвечаем за качество кода, используем только лучшие технические решения.
                                </div>
                            </div>

                            <div data-wow-delay="0.8s" className='flex flex-col w-full lg:w-1/4 h-[250px] wow fadeIn'>
                                <div className='flex flex-row items-center gap-2' > <div className='w-[50px]'>  < Glasses width={"50px"} height={"50px"} /> </div> <div className='text-[#B480FD] text-[22px]'>Ответственность</div></div>
                                <div className='text-[#ffffff] text-[18px]'>
                                    Несём полную ответственность за результат каждого сотрудника и проекта в целом.
                                </div>
                            </div>

                            <div data-wow-delay="0.9s" className='flex flex-col w-full lg:w-1/4 h-[250px] wow fadeIn'>
                                <div className='flex flex-row items-center gap-2' >  <div className='w-[50px]'> < Team width={"50px"} height={"50px"} />  </div> <div className='text-[#B480FD] text-[22px]'>Профессиональная команда</div></div>
                                <div className='text-[#ffffff] text-[18px]'>
                                    Мы умеем работать с разными технологиями и решениями, реализовываем как локальные, так и международные проекты на одинаково высоком уровне.
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <div className='w-full block md:hidden'>
                    <Carousel autoplay>
                        <div className='flex flex-col w-full lg:w-1/4 h-[250px] mx-2'>
                            <div className='flex flex-row items-center gap-2 ' > <div className='w-[50px]'> < Layer width={"50px"} height={"50px"} /> </div><div className='text-[#B480FD] text-[22px]'>  Комплексный подход</div></div>
                            <div className='text-[#ffffff] text-[18px]'>
                                Ведём проекты от прототипирования до поддержки после запуска. Готовы подключиться на любом этапе и взять под контроль все процессы.
                            </div>
                        </div>

                        <div className='flex flex-col w-full lg:w-1/4 h-[250px] mx-2'>
                            <div className='flex flex-row items-center gap-2' > <div className='w-[50px]'>  < ClockIcon width={"50px"} height={"50px"} /> </div> <div className='text-[#B480FD] text-[22px]'>Оперативность</div></div>
                            <div className='text-[#ffffff] text-[18px]'>
                                Разделяем крупные проекты на этапы, соблюдаем договоренности о сроках, запускаем проекты без задержек.
                            </div>
                        </div>

                        <div className='flex flex-col w-full lg:w-1/4 h-[250px] mx-2'>
                            <div className='flex flex-row items-center gap-2' >  <div className='w-[50px]'> < Individual width={"50px"} height={"50px"} />  </div> <div className='text-[#B480FD] text-[22px]'>Индивидуальное решение </div></div>
                            <div className='text-[#ffffff] text-[18px]'>
                                Разработаем решение, которое подойдёт именно для вашего бизнеса с учётом особенностей бизнес-модели и предпочтений ваших клиентов.
                            </div>
                        </div>


                        <div className='flex flex-col w-full lg:w-1/4 h-[250px] mx-2'>
                            <div className='flex flex-row items-center gap-2 ' > <div className='w-[50px]'> < Code width={"50px"} height={"50px"} /> </div><div className='text-[#B480FD] text-[22px]'>Чистый код</div></div>
                            <div className='text-[#ffffff] text-[18px]'>
                                Отвечаем за качество кода, используем только лучшие технические решения.
                            </div>
                        </div>

                        <div className='flex flex-col w-full lg:w-1/4 h-[250px] mx-2'>
                            <div className='flex flex-row items-center gap-2' > <div className='w-[50px]'>  < Glasses width={"50px"} height={"50px"} /> </div> <div className='text-[#B480FD] text-[22px]'>Ответственность</div></div>
                            <div className='text-[#ffffff] text-[18px]'>
                                Несём полную ответственность за результат каждого сотрудника и проекта в целом.
                            </div>
                        </div>

                        <div className='flex flex-col w-full lg:w-1/4 h-[250px] mx-2'>
                            <div className='flex flex-row items-center gap-2' >  <div className='w-[50px]'> < Team width={"50px"} height={"50px"} />  </div> <div className='text-[#B480FD] text-[22px]'>Профессиональная команда</div></div>
                            <div className='text-[#ffffff] text-[18px]'>
                                Мы умеем работать с разными технологиями и решениями, реализовываем как локальные, так и международные проекты на одинаково высоком уровне.
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div data-wow-delay="1s" className='wow fadeIn'>
                    <div className='flex flex-col items-center '>
                        <div data-wow-delay="0.1s" className='gogh text-[22px] text-[#B480FD] wow fadeIn'>
                            Approach to work
                        </div>
                        <div data-wow-delay="0.2s" className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff] wow fadeIn'>
                            Подход к работе
                        </div>
                        <p data-wow-delay="0.3s" className='gogh text-center leading-none mt-[10px] text-[18px] text-[#ffffff]  max-w-[700px] wow fadeIn'>
                            В зависимости от специфики проекта мы используем один из двух подходов
                        </p>
                    </div>




                    <div className=' flex flex-col md:flex-row mt-[50px]  gap-[40px] max-w-[1300px]'>

                        <div className='rounded-[50px] border-[5px] border-[#B480FD]  px-[20px] pt-[20px] mx-[5px]'>

                            <div className='text-[#B480FD] text-[32px]'>Time&Material</div>

                            <div className='text-[#ffffff]'>Проект разбивается на задачи, которые оцениваются отдельно.
                                Когда задача выполнена — вы оплачиваете работу команды.
                                Оплата может происходить после спринта, ежемесячно, раз в квартал
                                и т.д. — как вам удобно. Эта модель идеально подходит для реализации
                                MVP или проектов, которые могут поменяться в зависимости от специфики рынка.
                                Релиз и тестирование проекта происходит по окончании каждого этапа.</div>


                            <div className='flex flex-col py-[20px] gap-4'>

                                <div className='flex flex-row gap-4' >
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Wave width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Гибкость реализации</div>
                                        <div className='text-[14px]'>Можно вносить изменения в техническое задание во время реализации проекта</div>
                                    </div>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Fire width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Срочность старта</div>
                                        <div className='text-[14px]'>Можно начинать без составления сложного технического задания и полного проекта</div>
                                    </div>
                                </div>


                                <div className='flex flex-row gap-4'>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Dollar width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Оптимизация стоимости</div>
                                        <div className='text-[14px]'>Стоимость проекта меняется как в большую, так и в меньшую сторону по мере внесения изменений в план реализации </div>
                                    </div>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Dialog width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Удобство взаимодействия</div>
                                        <div className='text-[14px]'>Заказчик и руководитель проекта глубоко вовлечены в процесс реализации</div>
                                    </div>
                                </div>


                                <div className='flex flex-row'>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < GlassCube width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Прозрачность работы</div>
                                        <div className='text-[14px]'> Оплата производится по факту за конкретно выполненные работы </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                        <div className='rounded-[50px] border-[5px] border-[#B480FD] px-[20px] pt-[20px] mx-[5px]'>

                            <div className='text-[#B480FD] text-[32px]'>Fixed Price</div>

                            <div className='text-[#ffffff]'>Бюджет на разработку всего проекта и его Техническое
                                Задание утверждается перед стартом работ и остается неизменным. Также перед
                                началом работ утверждается точный календарный срок сдачи проекта, который
                                основывается на Техническом Задании. Таким образом, вы точно знаете стоимость
                                и сроки проекта, но не сможете внести изменения по ходу разработки. Релиз
                                и тестирование проекта происходит по окончании всех работ.</div>


                            <div className='flex flex-col py-[20px] gap-4'>

                                <div className='flex flex-row gap-4' >
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Secure width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Финансовые гарантии</div>
                                        <div className='text-[14px]'>Стоимость проекта понятна еще до начала работ</div>
                                    </div>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Celendar width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Точные сроки</div>
                                        <div className='text-[14px]'> Дата завершения проекта фиксируется в момент подписания договора </div>
                                    </div>
                                </div>


                                <div className='flex flex-row gap-4'>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < ClockIcon2 width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Экономия времени</div>
                                        <div className='text-[14px]'> Можно не тратить время на общение с исполнителями и командой на этапе реализации продукта </div>
                                    </div>
                                    <div className='flex flex-col text-[#fff] w-1/2'>
                                        < Test width={"50px"} height={"50px"} />
                                        <div className='gogh-heavy'>Точность реализации</div>
                                        <div className='text-[14px]'> План работ и итоговый функционал понятен до начала реализации </div>
                                    </div>
                                </div>





                            </div>

                        </div>

                    </div>


                </div>

                <Foooter footer={footer} />

                <ArrowAnchor scrollToTop={scrollToTop} />

            </div >

        </>
    )
}

