import React, { useEffect } from 'react';
import './TranscaledSquares.css';
import WOW from 'wowjs'

const TranscaledSquares = ({ scrollToShowCase, transcaledSquares }) => {

    const squareCount = 30;
    const animationDelayStep = 0.2;
    const squares = [];





    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        // Возвращаем функцию очистки при размонтировании компонента
        return () => {
            document.body.style.overflow = '';
        };
    }, []);





    for (let i = 0; i < squareCount; i++) {
        const animationDelay = (squareCount - i) * animationDelayStep;

        const squareStyle = {
            animationName: 'scaleAnimation',
            // animationDelay: `${animationDelay}s`,
            transform: `scale(${0.4 + i * 1.2 * 0.05}) rotate(-${i * 1.3}deg)`,
        };

        const dataWoWDelay = `${animationDelay}s`

        squares.push(
            <div
                key={i}
                className="square w-[calc(100vw)] md:h-[100vh] wow fadeIn"
                style={squareStyle}
                data-wow-delay={dataWoWDelay}
            ></div>
        );
    }

    return (
        <>
            <div ref={transcaledSquares} className="relative overflow-hidden md:w-[calc(100vw-17px)] w-[100vw]  md:h-screen border-b-[1px] border-t-[1px] border-[#B480FD] md:block hidden">
                {squares}
                <div className='flex w-full h-full items-center justify-center text-[#fff]'>
                    <div className='] w-[35vw] h-[30vh] p-3 flex flex-col gap-5 items-center justify-center z-50'>
                        <div className='w-full gogh-heavy leading-none text-center md:text-[30px] text-[#ffffff] '>
                            РАЗРАБОТКА ВЕБ, МОБИЛЬНЫХ И ДЕСКТОПНЫХ ПРИЛОЖЕНИЙ
                        </div>
                        <div className='w-full gogh leading-none text-center md:text-[22px]  text-[#B480FD] '>
                            Мы предоставляем услуги Full-stack разработки продуктов с привязкой к конкретным KPI и бизнес-задачам.
                        </div>


                        <div className='hover:bg-[#B480FD] bg-[#5E3FDB] text-[#fff] duration-700 w-[300px] py-2 px-4 hover:cursor-pointer text-center hidden md:block' onClick={() => { scrollToShowCase() }} >
                            Подробнее
                        </div>

                    </div>



                </div>
            </div>
        </>
    );
};

export default TranscaledSquares;








