import React, { useEffect } from 'react';
import { ElectronJs, NodeJS, ReactJs, SvelteJs, NextJs } from "../components/img/svg.js";
import WOW from 'wowjs'

const Technologies = ({ }) => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
        return () => {
            document.body.style.overflow = '';
        };
    }, []);


    return (
        <>

            <div className='flex flex-col items-center justify-center md:h-[500px] h-[200px] border-b-[1px] border-[#B480FD]'>
                <div data-wow-delay="0.1s" className='gogh text-[22px] text-[#B480FD] wow fadeIn'>
                    TECHNOLOGIES
                </div>

                <div data-wow-delay="0.2s" className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff] wow fadeIn'>
                    ТЕХНОЛОГИИ
                </div>

                <div className='flex flex-row justify-around w-full mt-[50px] hidden md:block md:flex md:flex-row'>
                    <div data-wow-delay="0.3s" className=" wow fadeIn">
                        < ReactJs width={"150px"} height={"150px"} />
                    </div>
                    <div data-wow-delay="0.4s" className=" wow fadeIn">
                        < SvelteJs width={"150px"} height={"150px"} />
                    </div>
                    <div data-wow-delay="0.5s" className=" wow fadeIn">
                        < NodeJS width={"150px"} height={"150px"} />
                    </div>
                    <div data-wow-delay="0.6s" className=" wow fadeIn">
                        < NextJs width={"150px"} height={"150px"} />
                    </div>
                    <div data-wow-delay="0.7s" className=" wow fadeIn">
                        < ElectronJs width={"150px"} height={"150px"} />
                    </div>
                </div>


                <div className='flex flex-row justify-around w-full mt-[50px] block md:hidden'>
                    <div data-wow-delay="0.3s" className=" wow fadeIn">
                        < ReactJs width={"50px"} height={"50px"} />
                    </div>
                    <div data-wow-delay="0.4s" className=" wow fadeIn">
                        < SvelteJs width={"50px"} height={"50px"} />
                    </div>
                    <div data-wow-delay="0.5s" className=" wow fadeIn">
                        < NodeJS width={"50px"} height={"50px"} />
                    </div>
                    <div data-wow-delay="0.6s" className=" wow fadeIn">
                        < NextJs width={"50px"} height={"50px"} />
                    </div>
                    <div data-wow-delay="0.7s" className=" wow fadeIn">
                        < ElectronJs width={"50px"} height={"50px"} />
                    </div>
                </div>

            </div>
        </>
    );
};

export default Technologies;














