import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { NotFound } from './pages/NotFound'
import { Main } from './pages/Main'
import { ItTurbo } from './pages/projects/ItTurbo'
import { Afir } from './pages/projects/Afir'
import { ShowCase } from './pages/ShowCase.js'
import { Ontory } from './pages/projects/Ontory.js'
import { SATT } from './pages/projects/SATT.js'
import { SDMT } from './pages/projects/SDMT.js'
import { TeensKids } from './pages/projects/TeensKids.js'
import { TechnologiesAll } from './pages/TechnologiesAll';
import { AboutUs } from './pages/AboutUs';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/it-turbo" element={<ItTurbo />} />
          <Route path="/afir" element={<Afir />} />
          <Route path="/showcase" element={<ShowCase />} />
          <Route path="/technology" element={<TechnologiesAll />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/ontory" element={<Ontory />} />
          <Route path="/satt" element={<SATT />} />
          <Route path="/sdmt" element={<SDMT />} />
          <Route path="/teenskids" element={<TeensKids />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </Router>
  )
}
export default App;
