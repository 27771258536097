import React, { useState, useEffect, useRef } from 'react'
import StickyHeader from '../../components/StickyHeader.js'
import WOW from 'wowjs'
import { ClockIcon, Code, WebPage, GitBranch } from "../../components/img/svg.js";
// import webPAge from "../../components/img/web-page-svgrepo-com.svg";
// import git from "../../components/img/git-branch-svgrepo-com.svg";
import BurgerMenu from '../../components/BurgerMenu'
import ontoryShowCase1 from "../../components/img/ontoryShowCase/2.png";
import ontoryShowCase2 from "../../components/img/ontoryShowCase/4.png";
import ontoryShowCase3 from "../../components/img/ontoryShowCase/6.png";
import ontoryShowCase4 from "../../components/img/ontoryShowCase/7.png";
import ontoryShowCase5 from "../../components/img/ontoryShowCase/5.png";


import Foooter from '../../components/Footer.js'
export const Ontory = () => {

  const footer = useRef(null);
  const [burgerMenu, setBurgerMenu] = useState(false)

  const scrollToAnchor = () => {
    footer.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);


  return (
    <>

      <div className='scrollbar-hide'>
        <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
        <StickyHeader scrollToAnchor={scrollToAnchor} />
        <div className='flex flex-col md:flex-row w-full '>

        <div className='w-full md:w-1/2 text-[#fff]  p-[20px] flex flex-col justify-center items-center'>

        <div data-wow-delay="0.2s" className="text-[32px] 2xl:text-[62px] xl:text-[60px] lg:text-[48px] md:text-[36px] gogh-heavy wow fadeIn ">СИСТЕМА УПРАВЛЕНИЯ ИСХОДНЫМ КОДОМ</div>

        <div data-wow-delay="0.4s" className='text-[18px] 2xl:text-[28px] xl:text-[24px] lg:text-[20px] md:text-[16px] wow fadeIn'>
              Комлекс управления жизненным циклом программного обеспечения при разработке продуктов и решений в IT-отрасли
            </div>

          </div>




          <video data-wow-delay="0.6s" className="w-full md:w-1/2 md:h-[calc(70vh)] h-[calc(40vh)]  mr-[10px] wow fadeIn" autoPlay muted loop style={{ position: 'relative' }}>
            <source src={`${process.env.PUBLIC_URL}/video/ontory.mp4`} type="video/mp4" />
            Ваш браузер не поддерживает видео.
          </video>


        </div>

        <div className='text-[#fff] w-full flex flex-col items-center'>




        <div className='flex flex-col sm:flex-row items-center w-full  mt-[30px]'>
            {/* <div className='flex flex-row gap-4'> */}
            <div className='flex flex-col xl:flex-row justify-around w-full items-center gap-4'>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="0.8s" >
                <div className='text-[#B480FD]'>100% российское ПО</div>
                <div>Решения сделаны в РФ и находятся в Реестре российского ПО</div>
              </div>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1s">
                <div className='text-[#B480FD]'> Передовые технологии</div>
                <div> Использованы алгоритмы машинного обучения и искусственного интеллекта</div>
              </div>
            </div>
            {/* </div> */}

            {/* <div className='flex flex-row gap-4'> */}
            <div className='flex flex-col xl:flex-row justify-around w-full items-center gap-4'>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1.2s">
                <div className='text-[#B480FD]'>Максимальная безопасность</div>
                <div> Соответствует 152-ФЗ и корпоративным требованиям информационной безопасности</div>
              </div>
              <div className='w-[300px] flex flex-col wow fadeIn' data-wow-delay="1.4s">
                <div className='text-[#B480FD]'>Комплексный подход</div>
                <div>Функциональная готовность системы не уступает ведущим зарубежным решениям</div>
              </div>
            </div>
            {/* </div> */}
          </div>





          <div data-wow-delay="0.4s" className=' w-full flex flex-col items-center mt-[100px] wow fadeIn' >
            {/* <div> */}
            <div className='gogh text-[22px] text-[#B480FD]  '>
              ABOUT THE PRODUCT
            </div>
            <div className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff]  '>
              О ПРОДУКТЕ
            </div>
            {/* </div> */}

            <p className='gogh text-center leading-none text-[22px] text-[#ffffff]  max-w-[1200px]'>
              Комплексная система для управления жизненным циклом программного обеспечения при разработке продуктов и решений в IT-отрасли.

            </p>

          </div>

          <div className='h-[100px]'></div>



          <div className='flex flex-col gap-[50px] md:gap-[150px] w-full px-3 lg:w-2/3 px-3 justify-center items-center'>

<div data-wow-delay="0.5s" className='flex flex-col-reverse md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>
  <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                <img
             
                  src={ontoryShowCase1}
                  alt='Изображение'
                />

                {/* <Code width={"200px"} height={"200px"} /> */}

              </div>
              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Гибкая настройка прав доступа для разных ролей пользователей</div>
                Система предоставляет возможность настроить доступ к определенным функциям и данным для разных пользователей
                в зависимости от их роли в компании. Администратор имеет возможность создать несколько уровней доступа и быстро
                изменять настройки для каждого из них. Это обеспечивает безопасность данных и увеличивает продуктивность работы,
                разграничивая ответственность сотрудников. Также предусмотрена возможность просматривать журналы активности и
                узнавать, кто и когда получал доступ к данным. Мы гарантируем удобство использования системы и быструю настройку
                прав доступа для вашей компании
              </div>
            </div>


            <div data-wow-delay="0.5s" className='flex flex-col md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>

<div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
  <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Удобный web-редактор кода для быстрых изменений</div>
                Удобный web-редактор кода, позволяющий осуществлять быстрые и удобные изменения в коде.
                Редактор обладает широким функционалом, который способен удовлетворить требования различных
                уровней пользователей - от начинающих до профессионалов. Он позволяет значительно сократить
                время на редактирование кода благодаря наличию инструментов автодополнения кода, а также
                выявления ошибок в нём. Продукт представляет собой оптимальное решение для тех, кто не
                желает тратить много времени на редактирование кода вручную
              </div>

              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                <img
                  
                  src={ontoryShowCase2}
                  alt='Изображение'
                />

                {/* <ClockIcon width={"200px"} height={"200px"} /> */}
              </div>
            </div>




            <div data-wow-delay="0.5s" className='flex flex-col-reverse md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>
              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                <img
                  
                  src={ontoryShowCase3}
                  alt='Изображение'
                />
                {/* <WebPage width={"200px"} height={"200px"} /> */}
              </div>
              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Мощный поиск по коду и аналитический модуль</div>
                Система обладает мощным поиском по коду, благодаря которому вы быстро сможете найти нужный
                фрагмент в вашем проекте. Аналитический модуль в свою очередь позволяет проводить анализ
                кода и выявлять потенциальные ошибки до их появления. Это значительно экономит время и снижает
                вероятность возникновения проблем в будущем. Вам не придется проводить множество тестирований,
                чтобы найти и исправить ошибку. Наш продукт сделает эту работу за вас, предупредит о возможной
                проблеме и предложит вам корректное решение. Будьте уверены в надежности вашего кода благодаря нашим инструментам.
              </div>
            </div>




            <div data-wow-delay="0.5s" className='flex flex-col md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>

              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Интеграция с CI/CD для автоматического тестирования и развертывания</div>
                Система интеграции с CI/CD для автоматического тестирования и развертывания. Это решение поможет ускорить
                процесс разработки и улучшить качество вашего продукта. Мы предоставляем готовые инструменты для интеграции
                с вашей существующей системой CI/CD. Наше решение позволяет производить тестирование на каждом этапе разработки,
                начиная от компиляции кода и заканчивая развертыванием на этапе продакшена
              </div>

              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                <img
                  
                  src={ontoryShowCase4}
                  alt='Изображение'
                />
                {/* <GitBranch width={"200px"} height={"200px"} /> */}
              </div>
            </div>


            <div data-wow-delay="0.5s" className='flex flex-col-reverse md:flex-row w-full items-center justify-center gap-4 wow fadeIn'>
              <div className='flex w-full md:w-1/2 flex-row items-center justify-center wow fadeIn'>
                <img
                  className="w-[600px]"
                  src={ontoryShowCase5}
                  alt='Изображение'
                />
                {/* <WebPage width={"200px"} height={"200px"} /> */}
              </div>
              <div className='w-full md:w-1/2 text-[18px] 2xl:text-[18px] xl:text-[16px]  lg:text-[14px] md:text-[12px]'>
                <div className='text-[#B480FD] text-[32px] 2xl:text-[32px] xl:text-[28px]  lg:text-[24px] md:text-[20px]'>
                  Облегчение процесса code review и слияния изменений</div>
                Продукт призван облегчить процесс code review и слияния изменений. Он позволяет быстро и качественно
                проверять код на наличие ошибок и оптимизировать его для более эффективной работы. Инновационная
                технология обеспечивает максимальную точность анализа кода и минимизирует риски возможных ошибок.
                Благодаря нашему продукту процесс code review и слияния изменений становится гораздо легче и доступнее,
                что существенно повышает результативность разработки и сокращает затраты на исправление ошибок.
                Используйте наш продукт и добивайтесь новых успехов в разработке программного обеспечения!
              </div>
            </div>



          </div>


        </div>

        <div className='h-[100px]'></div>
      </div >

      <Foooter footer={footer} />

    </>
  )
}

