import React, { useState, useEffect, useRef } from 'react'
import WOW from 'wowjs'
import StickyHeader from '../components/StickyHeader.js'
import Foooter from '../components/Footer.js'
import BurgerMenu from '../components/BurgerMenu.js'
import ArrowAnchor from '../components/ArrowAnchor.js'
import { useNavigate } from 'react-router-dom';
import ItTurbo from "../components/img/it-turbo.png";
import TeensKids from "../components/img/teens&kids.png";
import SattImg from "../components/img/satt.png";
import SdmtImg from "../components/img/sdmt.png";
import { ArrowRightIcon } from '@heroicons/react/24/outline'

export const ShowCase = () => {

  const [burgerMenu, setBurgerMenu] = useState(false)
  const navigate = useNavigate();
  const footer = useRef(null);
  const header = useRef(null);
  const showCase = useRef(null);

  const scrollToAnchor = () => {
    footer.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToTop = () => {
    header.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = '';
    };
  }, []);


  return (
    <>
      <BurgerMenu setBurgerMenu={setBurgerMenu} burgerMenu={burgerMenu} scrollToAnchor={scrollToAnchor} />
      <StickyHeader scrollToAnchor={scrollToAnchor} />
      <div className='scrollbar-hide flex flex-col gap-[100px]'>
        <div ref={header}></div>



        <div ref={showCase} className=' w-full flex flex-col items-center'>
          <div data-wow-delay="0.1s" className='gogh text-[22px] text-[#B480FD] wow fadeIn'>
            SHOWCASE
          </div>
          <div data-wow-delay="0.2s" className='gogh-heavy leading-none md:text-[92px] text-[32px] text-[#ffffff] wow fadeIn'>
            ПОРТФОЛИО
          </div>
          <p data-wow-delay="0.3s" className='gogh text-center leading-none text-[18px] text-[#ffffff]  max-w-[700px] wow fadeIn'>
            Cписок проектов, в которых нам есть что показать:
          </p>

        </div>


        <div className='w-full flex md:flex-row px-1 flex-col justify-around mt-[20px] mt-[50px]'>

          <div data-wow-delay="0.4s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px]'>
            <div onClick={() => { navigate('/it-turbo'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <img
                className='object-cover bg-[#000] hover:bg-[#7051A0] hover:scale-110 duration-1000'
                src={ItTurbo}
                alt='Изображение'
              />
            </div>
            <div className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000' onClick={() => { navigate('/it-turbo'); }}>IT MANAGEMENT</div>
            <div className='text-[#fff] text-[18px] font-normal mt-[10px] md:text-[14px] lg:text-[18px]'>
              Иновационное решение для IT-команд по повышению качества и скорости разработки IT-продуктов
            </div>
            <div onClick={() => { navigate('/it-turbo'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>

          <div data-wow-delay="0.5s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] '>
            <div onClick={() => { navigate('/teenskids'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <img
                className='object-cover bg-[#000] hover:bg-[#7051A0] hover:scale-110 duration-1000'
                src={TeensKids}
                alt='Изображение'
              />
            </div>
            <div onClick={() => { navigate('/teenskids'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>TEENS & KIDS</div>
            <div className='text-[#fff] text-[18px] font-normal mt-[10px] md:text-[14px] lg:text-[18px]'>Лидер в России по предоставлению подросткам и их родителям онлайн психологической помощи </div>
            <div onClick={() => { navigate('/teenskids'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>

          <div data-wow-delay="0.6s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] '>
            <div onClick={() => { navigate('/afir'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <video className="h-[250px] object-cover bg-[#000] hover:bg-[#fff] hover:scale-110 duration-1000" autoPlay muted loop style={{ position: 'relative' }}>
                <source src={`${process.env.PUBLIC_URL}/video/afirvideo2.mp4`} type="video/mp4" />
                Ваш браузер не поддерживает видео.
              </video>
            </div>
            <div onClick={() => { navigate('/afir'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>Collaborative Text Editor</div>
            <div className='text-[#fff] text-[18px] font-normal mt-[10px] md:text-[14px] lg:text-[18px]'>Инновационное решение для автоматизации цикла разработки ПО и ИТ-продуктов на заказ</div>
            <div onClick={() => { navigate('/afir'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>

        </div>


        <div className='w-full flex md:flex-row px-1 flex-col justify-around mt-[20px] mt-[50px]'>

          <div data-wow-delay="0.7s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] '>
            <div onClick={() => { navigate('/ontory'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <video className=" object-cover bg-[#000] hover:bg-[#fff] hover:scale-110 duration-1000" autoPlay muted loop style={{ position: 'relative' }}>
                <source src={`${process.env.PUBLIC_URL}/video/ontorylow.mp4`} type="video/mp4" />
                Ваш браузер не поддерживает видео.
              </video>
            </div>
            <div onClick={() => { navigate('/ontory'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>
             Cистема управления
              исходным кодом
            </div>
            <div className='text-[#fff] text-[18px] font-normal mt-[10px] md:text-[14px] lg:text-[18px]'>Комлекс управления жизненным циклом программного обеспечения при разработке продуктов и решений в IT-отрасли</div>
            <div onClick={() => { navigate('/ontory'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>

          <div data-wow-delay="0.8s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px] '>
            <div
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <img onClick={() => { navigate('/satt'); }}
                className='object-cover bg-[#000] hover:bg-[#7051A0] hover:scale-110 duration-1000'
                src={SattImg}
                alt='Изображение'
              />

            </div>
            <div onClick={() => { navigate('/satt'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>Selecty Automated Testing Tool</div>
            <div className='text-[#fff] text-[18px] font-normal mt-[10px] md:text-[14px] lg:text-[18px]'>
              Инновационный профессиональный инструмент для тестирования информационных систем с использованием искусственного интеллекта</div>
            <div onClick={() => { navigate('/satt'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>

          <div data-wow-delay="0.9s" className='2xl:w-[500px] xl:w-[400px] lg:w-[300px] md:w-[200px]'>
            <div onClick={() => { navigate('/sdmt'); }}
              className='2xl:w-[500px] 2xl:h-[250px] xl:w-[400px] xl:h-[200px] lg:w-[300px] lg:h-[150px] md:w-[200px] md:h-[100px] flex flex-col  items-center overflow-hidden justify-center  hover:cursor-pointer'>
              <img onClick={() => { navigate('/satt'); }}
                className='object-cover bg-[#000] hover:bg-[#7051A0] hover:scale-110 duration-1000'
                src={SdmtImg}
                alt='Изображение'
              />

            </div>
            <div onClick={() => { navigate('/sdmt'); }} className='text-[#79798C] text-[18px] font-semibold mt-[20px] hover:text-[#B480FD] hover:cursor-pointer duration-1000'>Software Development Management Tool</div>
            <div className='text-[#fff] text-[18px] font-normal mt-[10px] md:text-[14px] lg:text-[18px]'>Инновационный профессиональный инструмент для управления ИТ-проектами и повышения эффективности ИТ-функции</div>
            <div onClick={() => { navigate('/sdmt'); }} className='text-[#B480FD] overflow-hidden underlining w-fit flex flex-row items-center gap-2 text-[18px] hover:cursor-pointer font-semibold mt-[20px] hover:text-[#B480FD]  duration-1000'>
              <div className=''>View Showcase </div>
              <ArrowRightIcon className='w-[20px]' />
            </div>
          </div>

        </div>

        <Foooter footer={footer} />

        <ArrowAnchor scrollToTop={scrollToTop} />

      </div >

    </>
  )
}

